.contact-form .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

@media (max-width: 600px) {
  .contact-form .grid {
    grid-template-columns: 1fr;
  }
}

.contact-form .grid > div {
  display: grid;
  gap: 10px;
}

.contact-form .grid .full-width {
  grid-column: 1 / -1;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px 15px;
}

.contact-form textarea {
  height: calc(6em * 1.2 + 20px);
  font-family: inherit;
}

.contact-form .flex {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  margin-top: 2rem;
}

@media (max-width: 600px) {
  .contact-form .flex {
    flex-wrap: wrap;
  }
  
  .contact-form .flex button {
    width: 100%;
  }
}

.contact-form .flex p {
  align-self: center;
}
