.social-links li {
  display: inline-block;
}

.social-links a {
  display: inline-block;
  font-size: 1.75rem;
  padding: .35em;
}

.social-links a:active .icon-facebook,
.social-links a:focus .icon-facebook,
.social-links a:hover .icon-facebook {
  filter: var(--filter-brand-facebook);
}

.social-links a:active .icon-instagram,
.social-links a:focus .icon-instagram,
.social-links a:hover .icon-instagram {
  filter: var(--filter-brand-instagram);
}

.social-links a:active .icon-linkedin,
.social-links a:focus .icon-linkedin,
.social-links a:hover .icon-linkedin {
  filter: var(--filter-brand-linkedin);
}

.social-links a:active .icon-linktree,
.social-links a:focus .icon-linktree,
.social-links a:hover .icon-linktree {
  filter: var(--filter-brand-linktree);
}

.social-links a:active .icon-pinterest,
.social-links a:focus .icon-pinterest,
.social-links a:hover .icon-pinterest {
  filter: var(--filter-brand-pinterest);
}

.social-links a:active .icon-tiktok,
.social-links a:focus .icon-tiktok,
.social-links a:hover .icon-tiktok {
  filter: var(--filter-brand-tiktok);
}

.social-links a:active .icon-twitter,
.social-links a:focus .icon-twitter,
.social-links a:hover .icon-twitter {
  filter: var(--filter-brand-twitter);
}

.social-links a:active .icon-x,
.social-links a:focus .icon-x,
.social-links a:hover .icon-x {
  filter: var(--filter-brand-x);
}
