footer {
  display: grid;
  place-items: center;
  gap: 25px;
  padding-block: 20px;
}

footer .copyright {
  font-size: small;
  display: inline-block;
}
