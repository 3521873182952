:root {
  --masonry-grid-gap: 150px;
}

@media (min-width: 600px) {
  :root {
    --masonry-grid-gap: 30px;
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
  margin-bottom: 2rem;
}

.categories label {
  padding: .33em .67em;
  border: 1px solid var(--color-outer-space);
  border-radius: 1em;
  cursor: pointer;
  user-select: none;
  transition: all .2s;
}

.categories label:has(:checked) {
  background-color: var(--color-outer-space);
  color: var(--color-white);
}

@media (hover: hover) {
  .categories label:hover {
    background-color: var(--color-outer-space);
    color: var(--color-white);
  }
}

.masonry-gallery .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-auto-rows: 1px;
  column-gap: var(--masonry-grid-gap);
}

.masonry-gallery .grid-item,
.masonry-gallery .tile {
  animation: fadeIn 1s;
}

.masonry-gallery .grid-item button {
  width: 100%;
  display: block;
  background-color: transparent;
  padding: 0;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: all .3s;
}

@media(hover: hover) {
  .masonry-gallery .grid-item button:hover {
    transform: scale(1.01);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .25);
  }
}

.masonry-gallery .grid-item img {
  width: 100%;
}

.masonry-gallery .text-container h2,
.modal .text-container h2 {
  display: inline;
  margin-right: 1em;
}

.masonry-gallery .text-container h2 + div,
.modal .text-container h2 + div {
  display: inline-flex;
  flex-wrap: wrap;
  gap: .5em 1em;
}

.masonry-gallery .text-container h2 ~ *,
.modal .text-container h2 ~ * {
  margin-top: 1em;
}

.masonry-gallery .tile-container {
  display: grid;
  gap: 8rem;
}

.masonry-gallery .tile {
  min-height: calc(100vh - 125px);
  min-height: calc(100svh - 125px);
  display: grid;
  align-content: start;
  gap: 1rem;
  transition: all .3s;
}

header.small ~ .masonry-gallery .tile {
  min-height: calc(100vh - 95px);
  min-height: calc(100svh - 95px);
}

.masonry-gallery .tile img {
  display: block;
  margin-inline: auto;
}

.masonry-gallery .tile-nav {
	display: flex;
	justify-content: space-around;
	padding-block: 20px;
	position: sticky;
	bottom: 0;
}

.masonry-gallery .tile-button {
  padding: 0;
  border: none;
  font-size: 1.8em;
  width: 1.75em;
  height: 1.75em;
  background: radial-gradient(var(--color-white) 0, transparent 66%), transparent;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
	z-index: 200;
  transition: all .3s;
}

.masonry-gallery .tile-button i {
  width: 1.25em;
  height: 1.25em;
}
