.sidebar {
  position: fixed;
  left: 0;
  height: 100vh;
  height: 100svh;
  width: clamp(250px, 20%, 320px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: delayedFadeIn 1.5s;
}

.sidebar header {
  height: auto;
  padding-inline: 20px;
  grid-template-columns: 1fr;
  gap: 50px;
  position: static;
  animation: none;
}

.sidebar header nav ul {
  flex-direction: column;
  align-items: flex-end;
}

.sidebar header nav a::before {
  width: calc(100% - 1em);
  right: 3px;
}

.sidebar header nav a.active::before,
.sidebar header nav a:focus::before,
.sidebar header nav a:hover::before {
  width: calc(100% - .5em);
}

.sidebar header nav a::after {
  right: 0;
}

.sidebar footer {
  place-items: flex-end;
  padding-inline: 20px;
}

.sidebar .social-links {
  text-align: center;
}

.sidebar .social-links li:last-child a {
  margin-right: calc(20px - .35em);
}

.sidebar .copyright {
	margin-right: 20px;
}

.sidebar ~ main {
  margin-left: min(calc(20%), 320px);
}
