.modal-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, .5);
  backdrop-filter: blur(5px);
  z-index: 3;
}

.modal {
  position: absolute;
  inset: 2vh;
  max-width: 150vh;
  background-color: var(--color-white);
  padding: 30px;
  margin-inline: auto;
  overflow: hidden;
}

.modal .image-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.modal img {
  width: auto;
  max-height: 100%;
  object-fit: contain;
}

.modal .text-container {
  width: 100%;
	padding: 30px;
	position: absolute;
	bottom: 0;
  left: 0;
	background-color: var(--color-white);
  border: 1px solid var(--color-light-outer-space);
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, .25);
  z-index: 1;
  transition: all .3s;
}

.modal .text-container.closed {
  box-shadow: none;
	transform: translateY(100%);
}

.modal .text-container::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--color-white);
  z-index: 1;
}

.details-button {
  position: absolute;
  bottom: 100%;
  left: 30px;
}

.details-button,
.details-button::before,
.details-button::after {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.details-button::after {
  height: calc(100% + 5px);
}

.details-button i {
  height: 1em;
  filter: var(--filter-white);
  position: absolute;
  top: 50%;
  right: .75em;
  transform: translateY(-50%);
  transition: all .3s;
}

.modal .text-container > .details-button ~ * {
	position: relative;
	z-index: 1;
}

.modal-button {
  padding: 0;
  border: none;
  font-size: 1.8em;
  width: 2em;
  height: 2em;
  background: radial-gradient(var(--color-white) 0, transparent 66%), transparent;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s;
}

.modal-button i {
  width: 1.5em;
  height: 1.5em;
}

#close-modal {
  position: absolute;
  top: calc(2vh);
  right: max(2vh, 50% - 75vh);
}

#to-previous-modal {
  position: absolute;
  top: 50%;
  left: max(2vh, 50% - 75vh);
  transform: translateY(-50%);
}

#to-next-modal {
  position: absolute;
  top: 50%;
  right: max(2vh, 50% - 75vh);
  transform: translateY(-50%);
}
