.loading-panel {
  height: 100%;
  background-color: var(--color-white);
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader::before,
.loader::after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader::before {
  animation: ball1 1s infinite;
  background-color: var(--color-dark-salmon);
  box-shadow: 30px 0 0 var(--color-dark-fawn);
  margin-bottom: 10px;
}

.loader::after {
  animation: ball2 1s infinite;
  background-color: var(--color-outer-space);
  box-shadow: 30px 0 0 var(--color-dark-cambridge-blue);
}

@keyframes rotate {
  0% { transform: rotate(0deg) scale(0.8) }
  50% { transform: rotate(360deg) scale(1.2) }
  100% { transform: rotate(720deg) scale(0.8) }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--color-dark-fawn);
  }
  50% {
    box-shadow: 0 0 0 var(--color-dark-fawn);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--color-dark-fawn);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 var(--color-dark-cambridge-blue);
  }
  50% {
    box-shadow: 0 0 0 var(--color-dark-cambridge-blue);
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 var(--color-dark-cambridge-blue);
    margin-top: 0;
  }
}
