.hamburger-menu {
  position: absolute;
  top: 24px;
  right: 20px;
}

.menu-button {
  padding: 0;
  border: none;
  font-size: 2em;
  height: 1em;
  background-color: var(--color-white);
  border-radius: 5px;
  position: relative;
  z-index: 10;
}

.menu-button i {
  scale: 1;
  transition: filter .3s, scale .15s;
}

.menu-button i.is-toggling {
  scale: 0;
}

#mobile-menu {
  height: calc(100vh);
  height: calc(100svh);
  width: 100%;
  padding: 15px 20px 20px;
  position: fixed;
  top: 0;
  right: -100%;
  background-color: var(--color-white);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  overflow: auto;
  transition: all .3s;
}

#mobile-menu.is-open {
  right: 0;
}

#mobile-menu .main-nav {
  margin-bottom: auto;
}
