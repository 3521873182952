header {
  width: 100%;
  height: 110px;
  background-color: var(--color-white);
  padding: 10px 50px;
  display: grid;
  grid-template-columns: minmax(max-content, auto) minmax(max-content, 1fr);
  align-items: end;
  transition: all .3s;
  position: fixed;
  position: sticky;
  top: 0;
  z-index: 2;
  transition: all .3s;
  animation: delayedFadeIn 1.5s;
}

header.mobile {
  padding-inline: 20px;
}

header.small {
  height: 80px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .25);
}

header > a {
  height: 100%;
  min-height: 100%;
}

@media (max-width: 1500px) {
  header > a {
    width: max-content;
  }
}

header .logo {
  width: auto;
  max-height: 100%;
}

header nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

header.mobile nav ul {
  flex-direction: column;
  align-items: center;
}

header nav a {
  display: block;
  padding: 1em;
  font-family: var(--font-heading);
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: .075em;
  text-decoration: none;
  position: relative;
}

header nav a::before {
  content: "";
  width: calc(100% - 1.5em);
  height: 2px;
  position: absolute;
  bottom: .65em;
  right: .75em;
  opacity: 0;
  transition: all .3s;
}

header nav a.active::before, header nav a:focus::before, header nav a:hover::before {
  width: calc(100% - 1em);
  right: .5em;
  opacity: 1;
}

header nav a::after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  bottom: calc(.65em + -5px);
  right: calc(50% - 3px);
  transform: translateY(-50%);
  opacity: 0;
  transition: all .3s;
}

header a.active {
  pointer-events: none;
}

header nav a.active,
header nav a:focus,
header nav a:hover {
  text-decoration: none;
}

header nav a.active::after,
header nav a:focus::after,
header nav a:hover::after {
  opacity: 1;
}

header.mobile nav li {
  display: block;
}

header.mobile nav a {
  font-size: 1.5rem;
}

header.mobile .social-links a {
  font-size: 2rem;
}
