.page--home {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page--home .grid {
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 5%;
  row-gap: 50px;
  place-items: center;
}

@media (max-width: 900px) {
  .page--home .grid {
    grid-template-columns: 1fr;
  }
}

.page--home .text-container {
  width: min-content;
  display: grid;
  gap: 25px;
  place-items: center;
}

.page--home .text-container div {
  text-transform: uppercase;
}

.page--home .text-container div:first-of-type {
  place-self: start;
}

.page--home h1 {
  font-size: clamp(5rem, 30vw, 7rem);
  color: var(--color-cambridge-blue);
}
